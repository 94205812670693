import axios from "axios";
import { getStore } from "@/utils/store";
import { Message, MessageBox } from 'element-ui'
import { clearStore } from '@/utils/store'
import router from '@/router/index'
const request = axios.create({
    // baseURL: 'http://192.168.254.105:8080',
    timeout: 20000,
    headers: { 'Content-Type': 'application/json' },
})

/* request拦截器 */
// 请求发出去前的拦截
request.interceptors.request.use(
    config => {
        config.headers.Accept = 'application/json;charset=UTF-8'
        let token = getStore({ name: "token" }) // 获取token
        // 每次请求将token加入到请求头中
        token && (config.headers.Authorization = 'Bearer ' + token)
        return config
    }, err => {
        // 报错则弹出网络状态差
        Message.warning('网络状态差')
        return Promise.reject(err)
    }
)

// 服务器返回请求时的拦截
request.interceptors.response.use(
    response => {

        let res = { code: response.data.code, data: response.data.data, msg: response.data.message }
        //将主体内容返回  
        return res
    }, error => {
        console.log(error);
        let { code, message } = error.response.data
        // 500登录过期
        let path = router.history.current.path
        if (code == 500) {
            MessageBox.confirm(message, '提示', {
                confirmButtonText: '重新登录',
                showCancelButton: false,
                type: 'warning',
                callback: action => {
                    clearStore({ type: 'session' })
                    window.localStorage.clear()
                    if (path == '/fileSign') {
                        window.location.href = '/fileSign'
                        return
                    }
                    if (path != '/multiplePeopleSign') {
                        // window.location.href = '/multiplePeopleSign'
                        // return
                        window.location.href = '/'
                    }
                }
            });
        } else {
            Message.warning(message || '系统升级维护中，请联系管理员！')
        }
        return error.response.data
        // return Promise.reject(error)
    })


export default request





