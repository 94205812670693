<template>
  <div class="main">
    <!-- <img :src="logoBlue" alt="" /> -->
    <el-dropdown class="dropdown" @command="dropHandler">
      <span class="el-dropdown-link">
        {{ userInfo.userName || userInfo.phone
        }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="perCenter">个人中心</el-dropdown-item>
        <el-dropdown-item command="logout">退 出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { clearStore } from "@/utils/store";
import { mapState } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      logoBlue: require("@/assets/images/logo/logo_blue.png"),
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    dropHandler(cmd) {
      this[cmd]();
    },
    //个人中心
    perCenter() {
      this.$router.push("/pages/perCenter");
    },
    //退出
    logout() {
      clearStore({ type: "session" });
      this.$router.push("/");
    },
  },
};
</script>
<style scoped lang='scss'>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .dropdown {
    padding-left: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px #fff solid;
  }
}
</style>