<template>
  <div>
    <div class="cropper-content">
      <!-- 剪裁框 -->
      <div class="cropper">
        <img ref="image" :src="imgFile" alt="" />
      </div>
      <!-- 预览框 -->
      <div
        class="show-preview"
        :style="{
          overflow: 'hidden',
          margin: '0 25px',
          display: 'flex',
          'align-items': 'center',
        }"
      >
        <div class="preview before" />
      </div>
    </div>
    <div class="footer-btn">
      <!-- 缩放旋转按钮 -->
      <div class="scope-btn">
        <el-tooltip class="item" effect="dark" content="放大" placement="top">
          <el-button
            icon="el-icon-zoom-in"
            size="mini"
            @click="cropperzoom(0.05)"
          />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="缩小" placement="top">
          <el-button
            icon="el-icon-zoom-out"
            size="mini"
            @click="cropperzoom(-0.05)"
          />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="逆时针旋转"
          placement="top"
        >
          <el-button
            size="mini"
            icon="el-icon-refresh-left"
            @click="cropperRotate(-10)"
          />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="顺时针旋转"
          placement="top"
        >
          <el-button
            size="mini"
            icon="el-icon-refresh-right"
            @click="cropperRotate(10)"
          />
        </el-tooltip>
      </div>

      <!-- 确认上传按钮 -->
      <div class="upload-btn">
        <!-- <el-button type="primary" @click="uploadImg('blob')">上传</el-button> -->
        <el-button @click="closeCropper" size="mini" type="warning" plain>重新上传</el-button>
      
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "JjSealUpload",
  props: {
    imgFile: {
      type: String,
      default: "",
    },
    autoCropWidth: {
      type: Number,
      default: 0,
    },
    autoCropHeight: {
      type: Number,
      default: 0,
    },
    sealColor: {
      type: String,
      default: "red",
    },
  },
  data() {
    return {
      myCropper: null,
      afterImg: "",
      ScaleX: 1,
      ScaleY: 1,
      fixed: false,
      fixedBox: false,
      inputRotate: 0,
      isDisabled: false,
    };
  },
  computed: {},
  watch: {
    imgFile(file) {
      this.imgFile = file;
    },
    sealColor(color) {},
  },
  mounted() {
    this.init();
  },
  methods: {
    closeCropper() {
      this.$emit("closeCropper");
    },
    handleChangeRotate(value) {
      this.cropperrotateTo(value);
    },
    init() {
      this.myCropper = new Cropper(this.$refs.image, {
        dragMode: "move",
        background: "#fff",
        preview: ".before",
        autoCropArea: 0.98,
        modal: false,
      });
      // this.getAfterImg()
    },
    // getAfterImg() {
    //   this.afterImg = this.myCropper
    //     .getCroppedCanvas({
    //       imageSmoothingQuality: "high",
    //     })
    //     .toDataURL("image/jpg");

    //   // console.log(this.afterImg, this.base64ToBlob(this.afterImg));
    //   this.$emit("upload", this.base64ToBlob(this.afterImg));
    // },

    // 缩放
    cropperzoom(val) {
      this.myCropper.zoom(val);
    },
    // 重置
    cropperReset() {
      this.myCropper.reset();
      this.ScaleX = 1;
      this.ScaleY = 1;
    },
    // 移动
    croppermove(val1, val2) {
      this.myCropper.move(val1, val2);
    },
    // 旋转
    cropperRotate(val) {
      this.myCropper.rotate(val);
    },
    // 绝对角度旋转
    cropperrotateTo(val) {
      this.myCropper.rotateTo(val);
    },
    // X轴翻转
    cropperScaleX() {
      this.ScaleX = -this.ScaleX;
      if (
        this.myCropper.getImageData().rotate === -90 ||
        this.myCropper.getImageData().rotate === 90
      ) {
        this.myCropper.scaleY(this.ScaleX);
      } else {
        this.myCropper.scaleX(this.ScaleX);
      }
    },
    // y轴翻转
    cropperScaleY() {
      // console.log(this.myCropper.getImageData().rotate)
      this.ScaleY = -this.ScaleY;
      if (
        this.myCropper.getImageData().rotate === -90 ||
        this.myCropper.getImageData().rotate === 90
      ) {
        this.myCropper.scaleX(this.ScaleY);
      } else {
        this.myCropper.scaleY(this.ScaleY);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cropper {
  // border: 1px solid #fff;
  // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  img {
    width: 100%;
    opacity: 0;
  }
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cropper-content .cropper {
  width: 315px;
  height: 315px;
  overflow: hidden;
}
.cropper-content .show-preview {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  overflow: hidden;
  /* border: 1px solid #cccccc; */
  background: #cccccc;
  margin-left: 40px;
}
.preview {
  overflow: hidden;
  // border: 1px solid #468ac8;
  // background: #cccccc;
}
.footer-btn {
  margin-top: 15px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.footer-btn .scope-btn {
  width: 260px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.footer-btn .scope-btn span {
  display: inline-block;
  padding: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
}
.footer-btn .scope-btn span i {
  font-size: 32px;
  color: #333333;
}
.footer-btn .upload-btn {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  margin-right: 25px;
}
.footer-btn .btn {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}
.solide {
  margin-left: 25px;
  width: 200px;
}
.before {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* 这个属性可以得到想要的效果 */
}
</style>
