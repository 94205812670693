
import company from './company'
import user from './user'
import admin from './admin'
import page404 from '@/components/error/404'

export default [
  ...company,
  ...admin,
  ...user,
  {
    hidden: true,
    path: '*',
    component: page404
  }
]