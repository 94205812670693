import Login from '@/pages/login/index.vue'
import Layout from '@/components/layout'
export default [
  // 首页
  {
    path: '/',
    name: 'login',
    component: Login
  },
  //跳转签章
  {
    path: '/fileSign',
    name: 'fileSign',
    component: () => import(/* webpackChunkName: "fileSign" */ '@/pages/sign/fileSign/index.vue')
  },
   //跳转多人签章
  {
    path: '/multiplePeopleSign',
    name: 'multiplePeopleSign',
    component: () => import(/* webpackChunkName: "multiplePeopleSign" */ '@/pages/sign/multiplePeopleSign/index.vue')
  },
  // 签名视图
  {
    path: '/signWebview',
    name: 'signWebview',
    component: () => import(/* webpackChunkName: "signWebview" */ '@/pages/sign/signWebview/index.vue')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Layout,
    children: [
      {
        hiddenByShowTree: true,
        path: 'webview',
        title: '嵌入页面',
        name: 'webview',
        component: () => import(/* webpackChunkName: "webview" */ '@/pages/webview/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'perCenter',
        title: '个人中心',
        name: 'perCenter',
        component: () => import(/* webpackChunkName: "perCenter" */ '@/pages/perCenter/index.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'buy',
        title: '个人中心',
        name: 'buy',
        component: () => import(/* webpackChunkName: "buy" */ '@/pages/buy/index.vue')
      },
    ]
  },
]