<template>
  <div class="card">
    <div class="card-title">
      <slot name="title"></slot>
      <slot name="btn"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "JjCard",
  computed: {},
};
</script>

<style  scoped lang='scss'>
.card {
  background: #fff;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .card-title {
    position: relative;
    font-size: 18px;
    padding: 12px 20px;
    font-weight: bold;
    border-bottom: 1px solid #ebeef5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep .card-content {
    padding: 20px;
    overflow: hidden;
  }
  ::v-deep .el-button {
    padding: 0;
  }
}
</style>
