<template>
  <div class="page-wrapper">
    <div class="header-container">
      <div class="header-left">
        <img class="logo" :src="logoBlue" alt="" />
        <p class="userType">{{ typeText() }}</p>
        <!-- <TopNavBar></TopNavBar> -->
      </div>
      <div class="header-right">
        <UserCenter></UserCenter>
      </div>
    </div>

    <div class="page-container">
      <div class="left-navBar">
        <LeftNavBar />
      </div>
      <div class="right-page">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
// import TopNavBar from "./header/topNavBar.vue";
import { mapState } from "vuex";
import LeftNavBar from "./leftNavBar.vue";
import UserCenter from "./header/userCenter.vue";
export default {
  components: { LeftNavBar, UserCenter },
  props: {},
  data() {
    return { logoBlue: require("@/assets/images/logo/logo_cloud.png") };
  },
  created() {},
  mounted() {},
  methods: {
    typeText() {
      return this.userInfo.userType == "0"
        ? "金建软件云签用章管理系统 - 个人版"
        : this.userInfo.userType == "1"
        ? "金建软件云签用章管理系统 - 企业版"
        : "金建软件云签客户后台管理系统";
    },
  },
  computed: { ...mapState(["userInfo"]) },
  watch: {},
};
</script>
<style scoped lang='scss'>
.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-container {
    width: 100%;
    height: 58px;
    padding: 0 20px;
    // background-color: #f2f2f2;
    background-color: #1e222d;
    color: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 99;
    .header-left {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      .logo {
        height: 26px;
        width: 26px;
      }
      .userType {
        margin-left: 20px;
        font-size: 18px;
        // font-weight: bold;
      }
    }
    .header-right {
      width: 600px;
      height: 100%;
    }
  }
  .page-container {
    // flex: 1;
    height: calc(100% - 58px);
    display: flex;
    .left-navBar {
      width: auto;
    }
    .right-page {
      flex: 1;
      // padding: 20px;
      background-color: #f3f4f7;
      //  overflow: auto;
    }
  }
}
</style>