import { getStore } from '@/utils/store'
const state = {
  token: getStore({ name: 'token' }),
  userInfo: getStore({ name: 'userInfo' }),
  listInfo: getStore({ name: 'listInfo' })||{},
  webUrl:'',
  backPath:'',
  showAside:false
}
export default state
