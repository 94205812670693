<template>
  <div class="main" :class="{ 'collapse-w': showAside }">
    <el-menu
      :collapse-transition="false"
      :default-active="activeId"
      :collapse="showAside"
      :unique-opened="true"
      class="el-menu-vertical-demo"
      background-color="#1e222d"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <template v-for="(item, index) in menus">
        <el-menu-item
          :key="index"
          :index="item.id"
          v-if="item.children.length <= 0"
          @click="curMenu(item)"
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>

        <el-submenu
          v-if="item.children.length > 0"
          :key="index"
          :index="item.id"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>

          <el-menu-item
            v-for="(it, inx) in item.children"
            :key="inx"
            :index="it.id"
            @click="curMenu(it)"
          >
            <span slot="title">{{ it.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>

    <div class="menu-footer">
      <div class="fold-icon" @click="toggle">
        <i :class="!showAside ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { setStore, getStore } from "@/utils/store";
import { leftMenu } from "@/api/common";
export default {
  components: {},
  props: {},
  data() {
    return {
      activeId: "",
      showAside: false,
      logoBlue: require("@/assets/images/logo/logo_blue.png"),
      menus: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getLeftMenu();
  },
  mounted() {},
  methods: {
    //获取左侧菜单栏
    async getLeftMenu() {
      let { code, data } = await leftMenu(this.login);
      if (code == 200) {
        this.menus = data;
        this.nodeActiveId();
      }
    },
    //初始化当前节点高亮
    nodeActiveId() {
      let active = getStore({ name: "activeId" });
      if (active) {
        this.activeId = active;
      } else {
        if (this.menus && this.menus.length > 0) {
          if (this.menus[0].children.length > 0) {
            this.activeId = this.menus[0].children[0].id;
          } else {
            this.activeId = this.menus[0].id;
          }
          setStore({
            name: "activeId",
            content: this.activeId,
            type: "session",
          });
        }
      }
    },
    //点击当前节点
    curMenu(item) {
      this.activeId = item.id;
      setStore({ name: "activeId", content: item.id, type: "session" });
       setStore({ name: "listInfo", content: {}, type: "session" });
      this.$store.commit('listInfo', {})
      this.$router.push({
        path: item.path,
      });
    },
    toggle() {
      this.showAside = !this.showAside;
      this.$store.commit("showAside", this.showAside);
    },
  },
};
</script>
<style scoped lang='scss'>
.collapse-w {
  width: 64px !important;
  transition: 0.3s;
}
.main {
  width: 202px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  .el-menu {
    width: 100%;
    height: 100%;
    border-right: none;
    ::v-deep .el-menu-item:hover {
      background-color: #262f3e !important;
    }
    ::v-deep .el-menu--inline .el-menu-item:hover {
      background-color: #262f3e !important;
    }
    ::v-deep .el-menu--inline .el-menu-item {
      background-color: #151822 !important;
    }
    ::v-deep .is-active .el-submenu__title {
      i:nth-child(1) {
        color: #ffd04b !important;
      }
    }
    ::v-deep .el-submenu__icon-arrow {
      font-size: 16px;
      color: #fff;
    }
  }
  .menu-footer {
    background-color: #262f3e;
    height: 56px;
    width: 100%;
    .fold-icon {
      width: 64px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
        color: #999;
      }
    }
    .fold-icon:hover {
      background-color: #344258;
      transition: 0.3s;
    }
  }
}
</style>