// import config from '@/config'

export function isNull(val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

export const setStore = params => {
  const { name, content, type } = params
  const obj = {
    datetime: new Date().getTime(),
    dataType: typeof content,
    content,
    type
  }
  if (type) window.sessionStorage.setItem(name, JSON.stringify(obj))
  else window.localStorage.setItem(name, JSON.stringify(obj))
}

export const getStore = params => {
  const { name, debug } = params
  let obj = {}
  let content
  let session = window.sessionStorage.getItem(name)
  obj = session ? session : window.localStorage.getItem(name)
  if (isNull(obj)) return obj
  if (debug) {
    return obj
  }

  obj = JSON.parse(obj)

  if (obj.dataType === 'string') {
    content = obj.content
  } else if (obj.dataType === 'number') {
    content = Number(obj.content)
  } else if (obj.dataType === 'boolean') {
    // eslint-disable-next-line no-eval
    content = eval(obj.content)
  } else if (obj.dataType === 'object') {
    content = obj.content
  }
  return content
}

// 页面参数存取
export const sessionParams = params => {
  let item = {}
  const { name, value } = params
  if (value !== '{}') {
    window.sessionStorage.setItem(name, value)
    item = JSON.parse(value)
  } else {
    item = JSON.parse(window.sessionStorage.getItem(name))
  }
  return item
}

// 清除缓存
export const removeStore = params => {
  const { name } = params
  window.localStorage.removeItem(name)
  window.sessionStorage.removeItem(name)
}

export const getAllStore = params => {
  const list = []
  const { type } = params
  for (let i = 1; i <= window.sessionStorage.length; i++) {
    if (type) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      })
    } else {
      list.push(
        getStore({
          name: window.localStorage.key(i),
          content: getStore({
            name: window.localStorage.key(i)
          })
        })
      )
    }
  }

  return list
}

export const clearStore = params => {
  const { type } = params
  if (type) {
    window.sessionStorage.clear()
    return
  }
  window.localStorage.clear()
}
