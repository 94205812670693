<template>
  <div class="err-404">
    <img src="~@/assets/images/error.jpg" />
    <h1>404 Not Found</h1>
    <h2>The page you are looking for could not be found.</h2>
    <el-button type="primary" @click="toHome">返回首页</el-button>
  </div>
</template>

<script>
export default {
  name: "Err404",
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.err-404 {
  height: 100%;
  text-align: center;
  img {
    margin-top: 40px;
    width: 30%;
  }
}
</style>
