import Layout from '@/components/layout'
import Detail from '@/pages/admin/applyInfo/entInfo/detail.vue'

//管理者页面
export default [
  {
    path: '/statistic',
    name: 'statistic',
    component: Layout,
    children: [
      {
        hiddenByShowTree: true,
        path: 'dataSts',
        title: '数据统计',
        name: 'dataSts',
        component: () => import(/* webpackChunkName: "statistics" */ '@/pages/admin/dataStatistics')
      },
    ]
  },
  //申请信息
  {
    path: '/applyInfo',
    name: 'applyInfo',
    component: Layout,
    children: [
      {
        hiddenByShowTree: true,
        path: 'entInfo',
        title: '企业总览',
        name: 'entInfo',
        component: () => import(/* webpackChunkName: "entInfo" */ '@/pages/admin/applyInfo/entInfo/list.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'detail',
        title: '企业总览详情',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '@/pages/admin/applyInfo/entInfo/detail.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'perLists',
        title: '人员列表',
        name: 'perLists',
        component: () => import(/* webpackChunkName: "perList" */ '@/pages/admin/applyInfo/details/perList.vue')
      },
      {
        hiddenByShowTree: true,
        path: 'sealLists',
        title: '印章列表',
        name: 'sealLists',
        component: () => import(/* webpackChunkName: "sealList" */ '@/pages/admin/applyInfo/details/sealList.vue')
      },
      {
        path: 'details',
        name: 'details',
        component: Detail,
        children: [
          {
            hiddenByShowTree: true,
            path: 'basicInfo',
            title: '基本信息',
            name: 'basicInfo',
            component: () => import(/* webpackChunkName: "basicInfo" */ '@/pages/admin/applyInfo/details/basicInfo.vue')
          },
          {
            hiddenByShowTree: true,
            path: 'perList',
            title: '人员列表',
            name: 'perList',
            component: () => import(/* webpackChunkName: "perList" */ '@/pages/admin/applyInfo/details/perList.vue')
          },
          {
            hiddenByShowTree: true,
            path: 'sealList',
            title: '印章列表',
            name: 'sealList',
            component: () => import(/* webpackChunkName: "sealList" */ '@/pages/admin/applyInfo/details/sealList.vue')
          },
        ]
      },
    ]
  },

  // 企业信息
  {
    path: '/entSignInfo',
    name: 'entSignInfo',
    component: Layout,
    children: [
      {
        path: 'entList',
        title: '企业列表',
        name: 'entList',
        component: () => import(/* webpackChunkName: "entList" */ '@/pages/admin/entSignInfo/entList')
      },
      {
        path: 'detail',
        title: '企业详情',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '@/pages/admin/entSignInfo/entList/detail.vue')
      },
    ]
  },

]