import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Router.beforeEach((to, from, next) => {
//   let token = '' //校验token是否过期 是不是去login页面
//   if (to.path != '/'  && !token) {
//     // token过期从新登陆
//     next({ path: '/' })
//   } else {
//     if (to.path == '/' && token) {
//       next({ path: '/' })
//     } else {
//       next()
//     }
//   }
// })

export default Router
