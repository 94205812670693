import http from '@/api/http'
/**
 * @description: 结构树目录
 * @param {*} params
 * @return {*}
 */
 export const leftMenu = params => {
    return http.get(`/cloud/sign/left-menu`, params)
}



/**
 * @description: 认证中心上传
 * @param {*} params
 * @return {*}
 */
 export const upload = `/cloud/sign/upload/file`



 /**
 * @description: 获取认证登录网页链接
 * @param {*} params
 * @return {*}
 */
  export const loginPage = params => {
    return http.post(`/cloud/sign/ess/auth/login-page`, params)
}


/**
 * @description: 获取签署网页链接
 * @param {*} params
 * @return {*}
 */
 export const webUrl = params => {
  return http.post(`/cloud/sign/ess/doc/web-url`, params)
}

/**
 * @description: 查询印章类型
 * @param {*} params
 * @return {*}
 */
 export const sealType = params => {
  return http.post(`/cloud/sign/ess/seal/seal-type`, params)
}

/**
 * @description: 预览签署完成文件
 * @param {*} params
 * @return {*}
 */
 export const preview = params => {
  return http.post(`/documentSignFlow/ess/doc/resource-url`, params)
}


