import Layout from '@/components/layout'
export default [
  //认证中心
  {
    path: '/cfn',
    component: Layout,
    name: 'cfn',
    children: [
      {
        path: 'certification',
        title: '认证中心',
        name: 'certification',
        component: () => import(/* webpackChunkName: "certification" */ '@/pages/user/certification/index.vue')
      }
    ]
  },
  //印章管理
  {
    path: '/sealManage',
    name: 'sealManage',
    component: Layout,
    children: [
      {
        path: 'sealList',
        title: '企业印章',
        name: 'sealList',
        component: () => import(/* webpackChunkName: "sealList" */ '@/pages/user/sealManage/sealList.vue')
      },
      {
        path: 'sealDetail',
        title: '企业印章',
        name: 'sealDetail',
        component: () => import(/* webpackChunkName: "sealDetail" */ '@/pages/user/sealManage/sealDetail.vue')
      },
      {
        path: 'addSeal',
        title: '添加印章',
        name: 'addSeal',
        component: () => import(/* webpackChunkName: "addSeal" */ '@/pages/user/sealManage/addSeal.vue')
      },

    ]
  },
  //人员管理
  {
    path: '/personManage',
    name: 'personManage',
    component: Layout,
    children: [
      {
        path: 'personList',
        title: '人员管理',
        name: 'personList',
        component: () => import(/* webpackChunkName: "personList" */ '@/pages/user/personManage/personList.vue')
      },
      {
        path: 'addPerson',
        title: '添加人员',
        name: 'addPerson',
        component: () => import(/* webpackChunkName: "addPerson" */ '@/pages/user/personManage/addPerson.vue')
      },

    ]
  },
  // 合同签署
  {
    path: '/contractSign',
    name: 'contractSign',
    component: Layout,
    children: [
      {
        path: 'signList',
        title: '签署列表',
        name: 'signList',
        component: () => import(/* webpackChunkName: "signList" */ '@/pages/user/contractSign/signList/index.vue')
      },
      {
        path: 'fileSeal',
        title: '签章',
        name: 'fileSeal',
        component: () => import(/* webpackChunkName: "fileSeal" */ '@/pages/user/contractSign/fileSeal/index.vue')
      },
    ]
  },
  // 消耗统计
  {
    path: '/statistic',
    name: 'statistic',
    component: Layout,
    children: [
      {
        path: 'data',
        title: '消耗统计',
        name: 'data',
        component: () => import(/* webpackChunkName: "data" */ '@/pages/user/statistic/index.vue')
      },
      {
        path: 'detail',
        title: '消耗详情',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '@/pages/user/statistic/detail.vue')
      },
    ]
  },
]