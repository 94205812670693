<template>
  <div class="login-page" >
    <p class="title text-shadow-pop-bottom">金建软件云签管理系统</p>
    <template v-if="showType == 'login'">
      <Login @toggleType="toggleType"></Login>
    </template>
    <template v-if="showType == 'register'">
      <Register @toggleType="toggleType"></Register>
    </template>
    <template v-if="showType == 'resetPwd'">
      <ResetPwd @toggleType="toggleType"></ResetPwd>
    </template>
  </div>
</template>
<script>
import Login from "./login.vue";
import Register from "./register.vue";
import ResetPwd from "./resetPwd.vue";
export default {
  components: { Login, Register, ResetPwd },
  props: {},
  data() {
    return {
      showType: "login",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toggleType(type) {
      this.showType = type;
    },
  },
};
</script>
<style scoped lang='scss'>
.login-page {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/bg/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  p{
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 54px;
    color: #fff;
    letter-spacing: 5px;
  }
}


</style>