<template>
  <div class="register-main">
    <div class="register-card flip-in-ver-left">
      <p class="title">注 册</p>
      <div class="form rtr-form">
        <el-input v-model="form.phone" placeholder="请输入手机号">
          <i
            slot="prefix"
            class="el-icon-mobile"
            :class="{ 'icon-s': form.phone }"
          />
        </el-input>
        <el-input v-model="form.code" placeholder="请输入短信验证码">
          <i
            slot="prefix"
            class="el-icon-key"
            :class="{ 'icon-s': form.code }"
          />
          <i slot="suffix" class="obtain-code" @click="getCodeBtn">
            {{ isDisabledTime ? count + "s后获取" : "获取验证码" }}
          </i>
        </el-input>
        <el-input
          v-model="form.password"
          placeholder="请输入密码"
          show-password
        >
          <i
            slot="prefix"
            class="el-icon-lock"
            :class="{ 'icon-s': form.password }"
          />
        </el-input>
        <el-input
          v-model="form.newPwd"
          placeholder="请再次输入密码"
          show-password
        >
          <i
            slot="prefix"
            class="el-icon-lock"
            :class="{ 'icon-s': form.newPwd }"
          />
        </el-input>
      </div>
      <div class="btn" @click="goRtr">注 册</div>

      <p class="go-register">
        <span @click="toggleType">已有账号？ 立即登录</span>
      </p>
    </div>
  </div>
</template>
<script>
import { register, getVerificationCode } from "@/api/api/login";
export default {
  components: {},
  props: {},
  data() {
    return {
      form: {},
      isDisabledTime: false, //开始计时
      count: 0,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toggleType() {
      this.$emit("toggleType", "login");
    },
    //注册
    async goRtr() {
      if (!this.form.password || !this.form.newPwd) return;
      if (this.form.password != this.form.newPwd) {
        this.$message.warning("两次密码不一致！");
        return;
      }
      let { code, data } = await register(this.form);
      if (code == 200) {
        this.$message.success("注册成功！");
        this.$emit("toggleType", "login");
      }
    },
    async getCodeBtn() {
      if (this.isDisabledTime) return;
      const regMobile = /^1[3456789]\d{9}$/;
      if (!regMobile.test(this.form.phone) || this.form.phone == "") {
        this.$message.warning("手机号格式不正确");
        return;
      }
      let { code, data } = await getVerificationCode({
        phone: this.form.phone,
      });
      if (code == 200) {
        this.$message.success("短信验证码发送成功");
        this.countTime();
      }
    },
    // 开始倒计时
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.isDisabledTime = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.isDisabledTime = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang='scss'>
.register-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .register-card {
    width: 450px;
    height: 580px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 12%;
    padding: 40px 60px;
    .title {
      text-align: center;
      font-size: 40px;
    }
    .rtr-form {
      height: 280px;
      margin: 20px 0 30px 0;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .el-input {
        font-size: 16px;
      }
      ::v-deep .el-input__inner {
        height: 54px !important;
        border: none;
        background-color: #f2f2f2;
        border-radius: 50px;
        padding-left: 56px;
      }
      .icon-s {
        color: #51c1f3;
      }
      ::v-deep .el-input__suffix-inner {
        font-size: 16px;
        margin-right: 20px !important;
        line-height: 54px !important;
        cursor: pointer;
        color: #51c1f3;
      }
      .el-input__prefix {
        i {
          font-size: 24px;
          margin: 15px 0 0 18px;
        }
      }
    }
    .go-register {
      text-align: center;
      margin-top: 20px;
      color: #51c1f3;
      cursor: pointer;
    }
    .btn {
      height: 52px;
      line-height: 52px;
      font-size: 20px;
      //   font-weight: bold;
      text-align: center;
      background-color: #51c1f3;
      color: #fff;
      border-radius: 40px;
      cursor: pointer;
    }
  }
}
</style>