
//身份证中间*号表示
export const maskIDCard = (idCard)=> {
  if (idCard && idCard.length > 7) {
      let prefix = idCard.substring(0, 3); // 取前3位
      let suffix = idCard.substring(idCard.length - 4); // 取后4位
      let maskedPart = "*".repeat(idCard.length - 7); // 中间部分用星号代替
      return prefix + maskedPart + suffix;
  } else {
      return idCard; // 如果身份证号码过短，直接返回原字符串
  }
}


//获取地址栏上的参数 返回对象
export const getURLParams = () => {
  let url = window.location.href
  let params = {}
  let queryString = url.split('?')[1]
  if (queryString) {
    let pairs = queryString.split('&')
    for (let pair of pairs) {
      let [key, value] = pair.split('=')
      params[key] = decodeURIComponent(value)
    }
  }
  return params
}

export const decodeBase64=(params)  =>{
  const decodedParams = {};
  for (const key in params) {
    decodedParams[key] = base64Utf8(params[key].replace(/ /g, "+"));
  }
  return decodedParams;
}

export const base64Utf8=(base64Str) => {
  try {
    // 将Base64字符串解码为ArrayBuffer
    let binaryData = window.atob(base64Str);
    let len = binaryData.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }
    // 使用TextDecoder解码ArrayBuffer为UTF-8字符串
    let decoder = new TextDecoder("utf-8");
    let decodedText = decoder.decode(bytes);
    return decodedText;
  } catch (error) {
    console.error("解码Base64时出错:", error);
    return null;
  }
}

export const statusText = (num) => {
  switch (num) {
    case "0":
      return "未提交";
    case "2":
      return "已提交";
    case "-1":
      return "已驳回";
    default:
      return "已通过";
  }
}
// 获取未来日期和星期几 格式如[{date:'8月3号',week:'星期四'},{date:'8月5号',week:'星期五'}]
//settings 推后一天  numDays获取几天
export const getFutureDates = (numDays, settings) => {
  let futureDates = [];
  let today = new Date(); // 获取当前日期
  if (settings) today.setDate(today.getDate() + 1); // 获取明天的日期
  for (let i = 0; i < numDays; i++) {
    let futureDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000); // 计算未来日期
    let month = futureDate.getMonth() + 1; // 获取月份（注意：月份从0开始，需要加1）
    let day = futureDate.getDate(); // 获取日期
    let week = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ][futureDate.getDay()]; // 获取星期几
    let dateStr = month + "月" + day + "号"; // 拼接日期字符串
    futureDates.push({ date: dateStr, week: week }); // 添加到数组
  }
  return futureDates;
}


export const imageUrlToBase64 = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}