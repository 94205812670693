const mutaions = {
  userInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  showAside(state, showAside) {
    state.showAside = showAside
  },
  listInfo(state, listInfo) {
    state.listInfo = listInfo
  },
  webUrl(state, webUrl) {
    state.webUrl = webUrl
  },
  backPath(state, backPath) {
    state.backPath = backPath
  },
}
export default mutaions
