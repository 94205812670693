
import http from '@/api/http'
/**
 * @description: 保存企业电子印章
 * @param {*} params
 * @return {*}
 */
export const saveSealCreate = params => {
    return http.post(`/cloud/sign/ess/seal/seal-create`, params)
}
/**
 * @description: 添加法人印章
 * @param {*} params
 * @return {*}
 */
 export const addLegalPerSeal = params => {
    return http.post(`/cloud/sign/ess/legal-person-seal`, params)
}


/**
 * @description: 提交企业电子印章
 * @param {*} params
 * @return {*}
 */
 export const submitSealCreate = params => {
    return http.post(`/cloud/sign/ess/seal/seal-submit`, params)
}

/**
 * @description: 上传至腾讯
 * @param {*} params
 * @return {*}
 */
 export const auditSealCreate = params => {
    return http.post(`/cloud/sign/ess/seal/seal-audit`, params)
}

/**
 * @description: 查询企业印章列表
 * @param {*} params
 * @return {*}
 */
 export const sealList = params => {
    return http.post(`/cloud/sign/ess/seal/seal-list`, params)
}

/**
 * @description: 删除印章表
 * @param {*} params
 * @return {*}
 */
 export const delSeal = params => {
    return http.post(`/cloud/sign/ess/seal/seal-delete`, params)
}

// 印章详情
export const sealDetails = params => {
    return http.post(`/cloud/sign/ess/seal/seal-details`, params)
}

/**
 * @description: 停用印章表
 * @param {*} params
 * @return {*}
 */
 export const stopUsingSeal = params => {
    return http.post(`/cloud/sign/ess/seal/seal-stop`, params)
}

// /**
//  * @description: 启用印章表
//  * @param {*} params
//  * @return {*}
//  */
//  export const startSing = params => {
//     return http.post(`/cloud/sign/ess/seal/seal-start`, params)
// }


/**
 * @description: 授权经办人列表
 * @param {*} params
 * @return {*}
 */
 export const authPerList = params => {
    return http.post(`/cloud/sign/basic/person-auth/list`, params)
}


/**
 * @description: 授权列表
 * @param {*} params
 * @return {*}
 */
 export const authUserList = params => {
    return http.post(`/cloud/sign/ess/seal/auth-user`, params)
}

/**
 * @description: 确定授权
 * @param {*} params
 * @return {*}
 */
 export const createAuth = params => {
    return http.post(`/cloud/sign/ess/seal/create-auth`, params)
}

/**
 * @description: 取消授权
 * @param {*} params
 * @return {*}
 */
 export const cancelPerAuth = params => {
    return http.post(`/cloud/sign/ess/seal/cancel-auth`, params)
}

/**
 * @description: 支付
 * @param {*} params
 * @return {*}
 */
 export const pay = params => {
    return http.post(`/businessOrder/expect-payment`, params)
}

