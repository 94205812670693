<template>
  <div class="Jj-empty">
    <img class="err-img" src="@/assets/images/svg/empty.svg" alt="出错了" />
    <p class="err-msg">{{ msg }}</p>
  </div>
</template>
<script>
export default {
  name: "JjEmpty",
  props: {
    msg: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {};
  },
  watch: {
    schema(v) {
      this.searchSchema = v;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.Jj-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  p {
    color: #999;
    margin-top: 10px;
  }
}
</style>
