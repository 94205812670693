<template>
  <div class="jj-table">
    <el-table
      :data="tableData"
      :header-cell-style="{ color: '#666', backgroundColor: '#f3f4f7' }"
      height="250"
    >
      <!-- <el-table-column type="selection" width="55" align="center" :reserve-selection="true"></el-table-column> -->
      <el-table-column type="index" label="序号" width="50" align="center">
        <template slot-scope="scope">
          {{ (pager.curPage - 1) * pager.pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in tableTitle"
        :key="col.code"
        :label="col.name"
        :prop="col.code"
        show-overflow-tooltip
        align="center"
        :width="width(col)"
      >
        <template slot-scope="{ row }">
          <template
            v-if="
              col.code == 'operations' &&
              row.operations &&
              row.operations.length
            "
          >
            <el-link
              :type="item.type"
              class="action-item"
              v-for="(item, i) in row.operations"
              :key="i"
              @click="actionHandler(item.code, row)"
              ><span>{{ item.name }}</span>
            </el-link>
          </template>
          <el-link
            v-else-if="col.type === 'link'"
            class="link"
            type="primary"
            @click="toDetail(row)"
            >{{ row[col.code] }}</el-link
          >

          <span v-else-if="col.type === 'status'">
            <!-- <el-link type="warning" v-if="row[col.code] == '0'">待处理</el-link>
            <el-link type="primary" v-if="row[col.code] == '1'">进行中</el-link>
            <el-link type="success" v-if="row[col.code] == '2'">已完成</el-link> -->
            {{ row[col.code] }}
          </span>

          <span v-else-if="col.type === 'img' ">
            <img class="imgge" :src="config.baseUrl + row[col.code]" alt="" v-viewer />
          </span>

          <span v-else>{{ row[col.code] }} </span>
        </template>
      </el-table-column>
      <JjEmpty slot="empty" />
    </el-table>
    <div class="pager">
      <el-pagination
        background
        layout="total,sizes,prev,pager,next"
        @size-change="pagerSizeChange"
        @current-change="pagerCurrentChange"
        :current-page.sync="pagerOpt.curPage"
        :page-sizes="pagerOpt.sizes"
        :page-size="pagerOpt.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import { statusText } from "@/utils/utils";
import JjEmpty from "@/components/component/JjEmpty";
export default {
  name: "JjTable",
  components: { JjEmpty },
  props: {
    tableTitle: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    pager: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      statusText,
      config,
      pagerOpt: { curPage: 1, pageSize: 10, sizes: [10, 20, 30, 50] },
    };
  },
  computed: {},
  watch: {
    pager(v) {
      this.pagerOpt = v;
    },
  },
  created() {},
  mounted() {},
  methods: {
    width(col) {
      let w = col.width;
      if (col.code === "operations") w = col.with || 200;
      return w;
    },
    toDetail(row) {
      this.$emit("detail", row);
    },
    actionHandler(cmd, row) {
      this.$emit(cmd, row);
    },
    pagerSizeChange(e) {
      this.pagerOpt.pageSize = e;
      this.$emit("pageChange", this.pagerOpt);
    },
    pagerCurrentChange(e) {
      this.pagerOpt.curPage = e;
      this.$emit("pageChange", this.pagerOpt);
    },
  },
};
</script>
<style scoped lang='scss'>
.jj-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .action-item {
    padding: 0 5px;
  }
  .link {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; /* 这会显示省略号 */
    white-space: nowrap; /* 防止文本换行 */
  }
  .imgge{
    width: 100px;
    height: 100px;
  }
  .pager {
    text-align: center;
    padding: 10px 0;
  }
}
</style>