<template>
  <div class="login-main">
    <div class="login-card flip-in-ver-right">
      <p class="title">云 签 系 统</p>
      <div class="login-form">
        <el-input v-model="form.userName" placeholder="请输入账号">
          <i
            slot="prefix"
            class="el-icon-user"
            :class="{ 'icon-s': form.userName }"
          />
        </el-input>
        <el-input v-model="form.pwd" placeholder="请输入密码" show-password>
          <i
            slot="prefix"
            class="el-icon-lock"
            :class="{ 'icon-s': form.pwd }"
          />
        </el-input>
      </div>
      <p class="forget-pwd">
        <span @click="toggleType('resetPwd')">忘记密码？</span>
      </p>
      <div class="btn" @click="login">登 录</div>
      <p class="go-register" @click="toggleType('register')">
        <span>没有账号？前往注册</span>
      </p>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/api/login";
import { setStore } from "@/utils/store";
export default {
  components: {},
  props: {},
  data() {
    return {
      form: {
        userName: "",
        pwd: "",
      },
      register: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toggleType(type) {
      this.$emit("toggleType", type);
    },
    async login() {
      if (!this.form.userName || !this.form.pwd) return;
      let { code, data } = await login(this.form);
      if (code == 200) {
        setStore({ name: "token", content: data.token, type: "session" });
        setStore({ name: "userInfo", content: data, type: "session" });
        this.$store.commit("userInfo", data);
        // 0:普通用户 1:经办人  2:admin
        if (data.userType == "0"||data.userType == "1") {
          this.$router.push({ path: "/cfn/certification" });
        }
        if (data.userType == "2") {
          this.$router.push({ path: "/statistic/dataSts" });
        }
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.login-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .login-card {
    width: 450px;
    height: 580px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 12%;
    padding: 40px 60px;
    .title {
      text-align: center;
      font-size: 40px;
      //   letter-spacing: 10px;
    }
    .login-form {
      height: 150px;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .el-input {
        font-size: 16px;
      }
      ::v-deep .el-input__inner {
        height: 54px !important;
        border: none;
        background-color: #f2f2f2;
        border-radius: 50px;
        padding-left: 56px;
      }
      .icon-s {
        color: #51c1f3;
      }
      .el-input__prefix {
        i {
          font-size: 24px;
          margin: 15px 0 0 18px;
        }
      }
    }
    .forget-pwd span:hover {
      color: #51c1f3;
      cursor: pointer;
      transition: 0.3s;
    }
    .forget-pwd {
      text-align: end;
      margin-top: 8px;
      color: #999;
      transition: 0.3s;
    }
    .go-register {
      text-align: center;
      margin-top: 20px;
      color: #51c1f3;
      cursor: pointer;
    }
    .btn {
      margin-top: 70px;
      height: 52px;
      line-height: 52px;
      font-size: 20px;
      //   font-weight: bold;
      text-align: center;
      background-color: #51c1f3;
      color: #fff;
      border-radius: 40px;
      cursor: pointer;
    }
  }
}
</style>