import Vue from 'vue'
import Viewer from 'v-viewer';
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import { initApi } from './api/gApi'
import '@/plugins/vant'
import 'vant/lib/index.css';
import 'viewerjs/dist/viewer.css';
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'
import '@/components/component/index.js'


Vue.config.productionTip = false

Vue.use(ElementUI)
initApi(Vue)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999, // 设置图片预览组件的层级，确保能在其他组件之上
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
