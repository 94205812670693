<template>
  <div class="entSeal">
    <div class="top-card">
      <div class="left">
        <i class="el-icon-arrow-left back" @click="back" title="返回"></i>
        <el-radio-group class="radioGroup" v-model="actionType" size="small">
          <div
            style="padding-right: 10px"
            v-for="(item, index) in nodeList"
            :key="index"
            @click="tabChange(item)"
          >
            <el-radio-button :label="item.id">{{ item.name }}</el-radio-button>
          </div>
        </el-radio-group>
      </div>
    </div>
    <div class="btm-card">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import { getStore, setStore, removeStore } from "@/utils/store";
import { sealList } from "@/api/api/sealManage";
// import  BasicInfo  from "./cmd/basicInfo.vue";
export default {
  components: {},
  props: {},
  data() {
    return {
      config,
      actionType: "1",
      total: 0,
      pager: {
        curPage: 1,
        pageSize: 10,
      },
      curNode: {},
      nodeList: [
        { name: "基本信息", id: "1", path: "/applyInfo/details/basicInfo" },
        { name: "人员列表", id: "2", path: "/applyInfo/details/perList" },
        { name: "印章列表", id: "3", path: "/applyInfo/details/sealList" },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    let curNode = getStore({ name: "curNode" });
    if (curNode && curNode.path) {
      this.actionType = curNode.id;
      this.$router.push({ path: curNode.path });
    } else {
      this.actionType = this.nodeList[0].id;
      this.$router.push({ path: this.nodeList[0].path });
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.push({ path: "/applyInfo/entInfo" });
    },
    tabChange(item) {
      this.curNode = item.id;
      setStore({ name: "curNode", content: item, type: "session" });
      this.$router.push({ path: item.path });
    },
    async querySealList() {
      let { code, data } = await sealList();
      if (code == 200) {
        this.sealLists = data;
      }
    },

    pagerSizeChange(e) {
      this.pager.pageSize = e;
    },
    pagerCurrentChange(e) {
      this.pager.curPage = e;
    },
  },
     beforeDestroy(){
    removeStore({ name: 'curNode' })
  }
};
</script>
<style scoped lang='scss'>
.entSeal {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .top-card {
    width: 100%;
    height: 60px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 0 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .back {
        padding-right: 12px;
        font-size: 30px;
        cursor: pointer;
        color: #e6a23c;
      }
    }
    .radioGroup {
      //   padding: 10px 0;
      display: flex;
    }
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
      border-radius: 50px !important;
    }
    ::v-deep .el-input__icon {
      line-height: 30px;
    }
  }
  .btm-card {
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    ::v-deep .operate-box {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>