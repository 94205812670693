
import http from '@/api/http'
// 登录接口
export const login = params => {
    return http.post(`/cloud/sign/login`, params)
}

// 注册接口
export const register = params => {
    return http.post(`/cloud/sign/basic/register`, params)
}

// 重置密码
export const retrieve = params => {
    return http.post(`/cloud/sign/basic/reset-pw`, params)
}

// 获取验证码
export const getVerificationCode = params => {
    return http.post(`/cloud/sign/basic/send-msg`, params)
}