<template>
  <div>
    <template v-if="imgUrl">
      <div v-if="isPDF(imgUrl)" class="iframe-box">
        <iframe
          style="width: calc(100% + 17px); height: 100%"
          :src="config.baseUrl + imgUrl"
          frameborder="0"
          border="0"
        >
        </iframe>
        <div class="img-btn">
          <i class="el-icon-zoom-in" @click="imgPreview"></i>
          <i v-if="!edit" class="el-icon-delete" @click="handleRemove"></i>
        </div>
      </div>
      <div class="img-box" v-else>
        <img :src="config.baseUrl + imgUrl" class="avatar" />
        <div class="img-btn">
          <i class="el-icon-zoom-in" @click="imgPreview"></i>
          <i v-if="!edit" class="el-icon-delete" @click="handleRemove"></i>
        </div>
      </div>
    </template>

    <el-upload
      v-else
      class="upload"
      drag
      :disabled="edit"
      :action="upload"
      :headers="uploadHeaders"
      :on-error="onError"
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
      :show-file-list="false"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将<em v-if="type">【{{ type }}】</em>图片拖到此处，或<em>点击上传</em>
      </div>
      <span
        class="el-upload__tip"
        :class="{ 'tip-c': tip == 'center' }"
        slot="tip"
      >
        只能上传 {{ uplaodType.join("/") }} 格式<span
          v-if="wh && wh.width && wh.height"
          >，且宽高为{{ wh.width + "*" + wh.height }}之间</span
        >
        <!-- <em style="margin-left: 10px">查看示例</em> -->
      </span>
    </el-upload>
    <div class="title" v-if="imgUrl && title">{{ type }}</div>

    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="true"
      v-if="imgUrl"
      :visible.sync="previewVisible"
      :title="'预览'"
      custom-class="preview-dialog"
      width="80%"
      top="3%"
    >
      <iframe
        :src="config.baseUrl + imgUrl"
        style="width: 100%; height: 780px"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import config from "@/config";
import { mapGetters } from "vuex";
import { getStore } from "@/utils/store";
import { upload } from "@/api/common";
const imgArr = ["jpg", "png", "jpeg", "jpe", "webp"];
export default {
  name: "JjUpload",
  components: {},
  props: {
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    title: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
    tip: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: true,
    },
    uplaodType: {
      type: Array,
      default: () => [],
    },
    wh: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config,
      upload,
      imgUrl: "",
      previewVisible: false,
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "token" }),
      },
    };
  },
  computed: {
    // ...mapGetters(["uploadHeaders"]),
  },
  watch: {
    path(url) {
      this.imgUrl = url;
    },
  },
  created() {},
  mounted() {},
  methods: {
    isPDF(v) {
      return /\.pdf$/gi.test(v);
    },
    // 失败
    onError() {
      setTimeout(() => {
        this.$message.warning("当前网络拥堵，请重新上传！");
      }, 1500);
    },
    // 上传成功
    uploadSuccess(e) {
      if (e.data) {
        this.$emit("uploadSuccess", this.name, e.data.url, e.data);
      } else {
        this.$message.warning(e.message || "上传失败！");
      }
    },
    beforeUpload(file) {
      //限制格式
      let urlType = file.name.match(/[^.]+$/)[0].toLowerCase();
      if (!this.uplaodType.includes(urlType)) {
        this.$message.warning(
          `格式错误，请上传【 ${this.uplaodType.join("/")} 】格式`
        );
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!this.isPDF(file.name)&& !isLt5M) {
        // 文件大于5MB，提示错误
        this.$message.error("请上传5M以内的图片！");
        return false;
      }
      //限制宽高
      if (this.wh && this.wh.width && this.wh.height) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              const { width, height } = img;
              if (width > this.wh.width || height > this.wh.height) {
                this.$message.error("图片尺寸必须在200px*200px之间！");
                reject(file); // 不符合要求，返回false或者调用reject
              } else {
                resolve(file); // 符合要求，返回true或者调用resolve
              }
            };
            img.onerror = (err) => {
              reject(err); // 图片加载失败也拒绝上传
            };
          };
        });
      }
    },
    //删除img
    handleRemove() {
      this.$emit("uploadSuccess", this.name, "", {});
      this.imgUrl = "";
      this.$forceUpdate();
    },
    //预览
    imgPreview() {
      let type = this.imgUrl.match(/[^.]+$/)[0].toLowerCase();
      if (imgArr.includes(type)) {
        this.$viewerApi({ images: [config.baseUrl + this.imgUrl] });
      } else {
        this.previewVisible = true;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.upload {
  display: flex;
  flex-direction: column;
}
.el-icon-upload {
  color: #409eff !important;
}
.el-upload__tip {
  padding-top: 6px;
}
.tip-c {
  text-align: center;
}
.title {
  text-align: center;
  margin-top: 6px;
  color: #999;
}
.iframe-box {
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: absolute;
  top: 0;
  border-bottom: 1px solid;
}
.img-btn:hover {
  opacity: 1;
}
.img-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  color: #fff;
  opacity: 0;
  border-radius: 6px;
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}
.img-box {
  width: 360px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  opacity: 1;
  border: 1px dashed #dfdddd;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
</style>