<template>
  <div class="top-box">
    <i class="el-icon-back" @click="back"></i>
    <span>{{ title }}</span>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "JjBack",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: { ...mapState(["backPath"]) },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    back() {
      if (this.backPath) {
        this.$router.push({ path: this.backPath });
        this.$store.commit("backPath", "");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.top-box {
  width: 100%;
  min-height: 46px;
  background-color: #fff;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  i {
    padding: 0 10px;
    color: #f56c6c;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
  }
}
</style>