import { Message, MessageBox } from 'element-ui'

export const $asyncConfirm = (
  msg = '是否继续?',
  okText = '确定',
  cancelText = '取消',
  title = '提示',
  type = 'warning'
) => {
  return MessageBox.confirm(msg, title, {
    distinguishCancelAndClose: true,
    confirmButtonText: okText,
    cancelButtonText: cancelText,
    type
  })
    .then(e => {
      return e
    })
    .catch(e => {
      return e
    })
}

export function initApi(Vue) {
  Vue.prototype.$asyncConfirm = $asyncConfirm
}
